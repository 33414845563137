var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "v-card",
        { attrs: { elevation: "0", height: "'100%'" } },
        [
          _c(
            "v-card-title",
            { staticClass: "headline text-center" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-content-between",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { dense: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [_vm._v(" mdi-arrow-left")]
                      ),
                      _c(
                        "v-chip",
                        {
                          attrs: { color: "success", outlined: "", label: "" },
                        },
                        [_vm._v(_vm._s(_vm.form.Name))]
                      ),
                      _c("span"),
                    ],
                    1
                  ),
                  _vm.form.Description != null &&
                  _vm.form.Description.length > 0
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c(
                            "v-sheet",
                            {
                              staticClass: "border-primary",
                              attrs: {
                                outlined: "",
                                rounded: "",
                                color: "primary",
                                "max-width": 500,
                              },
                            },
                            [
                              _c(
                                "v-card-subtitle",
                                {
                                  staticClass: "text-break text-white",
                                  staticStyle: { "font-size": "12px" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.form.Description) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.ForCustomer && _vm.form.HasDifferentAssessmentUser
                    ? _c(
                        "v-col",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c("v-select", {
                            staticStyle: { "max-width": "500px" },
                            attrs: {
                              label: "Form Kullanıcısı",
                              dense: "",
                              outlined: "",
                              items: _vm.formUsers,
                              hint: "Formu kimin adına doldurduğunuzu seçiniz",
                              "persistent-hint": "",
                              "item-text": "UserFullName",
                              "item-value": "Id",
                            },
                            on: { change: _vm.formUserChanged },
                            model: {
                              value: _vm.selectedFormUserId,
                              callback: function ($$v) {
                                _vm.selectedFormUserId = $$v
                              },
                              expression: "selectedFormUserId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              class: _vm.$vuetify.breakpoint.mobile
                ? ""
                : "d-flex justify-content-center ",
            },
            [
              _c(
                "v-list",
                {
                  key: _vm.compKey,
                  attrs: { dense: "", flat: "", "max-width": "500" },
                },
                _vm._l(_vm.form.FormQuestions, function (question, i) {
                  return _c(
                    "v-list-group",
                    {
                      key: i,
                      staticClass:
                        "mx-1 my-2 border-primary border border-sm rounded",
                      attrs: {
                        value: true,
                        disabled: "",
                        "no-action": "",
                        "append-icon": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "mt-2 text-dark",
                                    staticStyle: { "font-size": "13px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(i + 1) +
                                        ". " +
                                        _vm._s(question.Text)
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      question.FormQuestionTypeName == "Checkbox"
                        ? _c("FormQuestionCheckboxType", {
                            attrs: {
                              formUser: _vm.formUser,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                      question.FormQuestionTypeName == "Matris"
                        ? _c("FormQuestionMatrixType", {
                            attrs: {
                              formUser: _vm.formUser,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                      question.FormQuestionTypeName == "Rating5"
                        ? _c("FormQuestionRating5Type", {
                            attrs: {
                              formUser: _vm.formUser,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                      question.FormQuestionTypeName == "Rating"
                        ? _c("FormQuestionRatingType", {
                            attrs: {
                              formUser: _vm.formUser,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                      question.FormQuestionTypeName == "Radio Button"
                        ? _c("FormQuestionRadioButtonType", {
                            attrs: {
                              formUser: _vm.formUser,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                      question.FormQuestionTypeName == "Doğru Yanlış"
                        ? _c("FormQuestionTrueFalseType", {
                            attrs: {
                              formUser: _vm.formUser,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                      question.FormQuestionTypeName == "Açık Uçlu"
                        ? _c("FormQuestionOpenEndedType", {
                            attrs: {
                              formUser: _vm.formUser,
                              formId: _vm.formId,
                              question: question,
                            },
                            on: {
                              getAnswers: function ($event) {
                                return _vm.getAnswersFromApi()
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    block: _vm.$vuetify.breakpoint.mobile,
                    large: !_vm.$vuetify.breakpoint.mobile,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("completed")
                    },
                  },
                },
                [_vm._v(" Formu Tamamla ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }