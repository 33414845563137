var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "table w-full" }, [
    _c("thead", [
      _c(
        "tr",
        { staticClass: "text-center" },
        [
          _c("th"),
          _vm._l(_vm.colNames, function (colName, index) {
            return _c("th", { key: index }, [
              _vm._v(" " + _vm._s(colName) + " "),
            ])
          }),
        ],
        2
      ),
    ]),
    _c(
      "tbody",
      _vm._l(_vm.rows, function (row, index) {
        return _c(
          "tr",
          { key: index, staticClass: "text-center" },
          [
            _c("td", [_vm._v(_vm._s(row.Text))]),
            _vm._l(_vm.colNames, function (colName, index) {
              return _c(
                "td",
                { key: index },
                [
                  _vm.formUser &&
                  _vm.formUser.FormUserQuestions &&
                  !_vm.formUser.FormUserQuestions.some(
                    (s) =>
                      s.FormUserQuestionOptionChilds.filter(
                        (d) =>
                          d.FormQuestionOptionChildId ==
                          row.Childs.find((s) => s.Text == colName).Id
                      )[0]
                  )
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "", loading: _vm.loadingSaveAnswer } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: { "pointer-events": "auto" },
                              attrs: { color: "grey darken-1" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveFormUserQuestionOptionChild(
                                    row,
                                    colName
                                  )
                                },
                              },
                            },
                            [_vm._v(" mdi-checkbox-blank-outline ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.formUser &&
                  _vm.formUser.FormUserQuestions &&
                  _vm.formUser.FormUserQuestions.some(
                    (s) =>
                      s.FormUserQuestionOptionChilds.filter(
                        (d) =>
                          d.FormQuestionOptionChildId ==
                          row.Childs.find((s) => s.Text == colName).Id
                      )[0]
                  )
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "", loading: _vm.loadingSaveAnswer } },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: { "pointer-events": "auto" },
                              attrs: { color: "grey darken-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteFormUserQuestionOption(
                                    row,
                                    colName
                                  )
                                },
                              },
                            },
                            [_vm._v(" mdi-checkbox-marked ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }