var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-textarea", {
    staticClass: "mx-2 my-2",
    attrs: {
      outlined: "",
      placeholder: "Buraya cevap yazılır",
      label: "Cevap",
      "persistent-hint": "",
      hint: _vm.hint,
      loading: _vm.loadingSaveAnswer,
    },
    on: {
      keyup: _vm.saveFormUserQuestionOption,
      keydown: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.saveFormUserQuestionOption.apply(null, arguments)
      },
    },
    model: {
      value: _vm.answer,
      callback: function ($$v) {
        _vm.answer = $$v
      },
      expression: "answer",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }