<template>
  <table class="table w-full">
    <thead>
      <tr class="text-center">
        <th></th>
        <th v-for="(colName, index) in colNames" :key="index">
          {{ colName }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="text-center" v-for="(row, index) in rows" :key="index">
        <td>{{ row.Text }}</td>
        <td v-for="(colName, index) in colNames" :key="index">
          <v-btn
            v-if="
              formUser &&
              formUser.FormUserQuestions &&
              !formUser.FormUserQuestions.some(
                (s) =>
                  s.FormUserQuestionOptionChilds.filter(
                    (d) =>
                      d.FormQuestionOptionChildId == row.Childs.find((s) => s.Text == colName).Id
                  )[0]
              )
            "
            icon
            :loading="loadingSaveAnswer"
          >
            <v-icon
              color="grey darken-1"
              style="pointer-events: auto"
              @click="saveFormUserQuestionOptionChild(row, colName)"
            >
              mdi-checkbox-blank-outline
            </v-icon>
          </v-btn>
          <v-btn
            v-if="
              formUser &&
              formUser.FormUserQuestions &&
              formUser.FormUserQuestions.some(
                (s) =>
                  s.FormUserQuestionOptionChilds.filter(
                    (d) =>
                      d.FormQuestionOptionChildId == row.Childs.find((s) => s.Text == colName).Id
                  )[0]
              )
            "
            icon
            :loading="loadingSaveAnswer"
          >
            <v-icon
              color="grey darken-2"
              style="pointer-events: auto"
              @click="deleteFormUserQuestionOption(row, colName)"
            >
              mdi-checkbox-marked
            </v-icon>
          </v-btn>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'form-question-matrix-type',
  props: ['question', 'formUser'],
  data() {
    return {
      loadingSaveAnswer: false,
      rows: [],
      cols: [],
      colNames: []
    }
  },
  watch: {
    question() {
      this.rows = []
      this.cols = []
      this.colNames = []
      this.findRowsAndCols()
    }
  },
  mounted() {
    this.findRowsAndCols()
  },
  methods: {
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
    findRowsAndCols() {
      for (let i = 0; i < this.question.FormQuestionOptions.length; i++) {
        const element = this.question.FormQuestionOptions[i]
        this.rows.push({
          Id: element.Id,
          Text: element.Text,
          Type: 'Parent',
          Childs: element.ChildQuestionOptions
        })
        for (let j = 0; j < element.ChildQuestionOptions.length; j++) {
          const object = element.ChildQuestionOptions[j]
          this.cols.push({ Id: object.Id, Text: object.Text, Type: 'Child', ParentId: element.Id })
          this.colNames.push(object.Text)
        }
      }
      this.colNames = this.colNames.filter(this.onlyUnique)
    },
    saveFormUserQuestionOptionChild(form_question_option, form_question_option_child) {
      this.loadingSaveAnswer = true
      let form_user_question = {
        FormUserId: this.formUser.Id,
        FormQuestionId: this.question.Id
      }
      ApiService.setHeader()
      ApiService.put('api/Form/FormUserQuestion', form_user_question).then((data) => {
        let FormUserQuestionId = data.data
        let form_user_question_option_child = {
          FormUserQuestionId: FormUserQuestionId,
          QuestionOptionChildId: form_question_option.Childs.find(
            (d) => d.Text == form_question_option_child
          ).Id
        }
        ApiService.put('api/Form/FormUserQuestionOptionChild', form_user_question_option_child)
          .then(() => {
            this.loadingSaveAnswer = false
            this.$emit('getAnswers')
          })
          .catch(({ response }) => {
            ApiService.showError(response)
            this.loadingSaveAnswer = false
          })
        ApiService.showSaveSuccess()
        this.loadingSaveAnswer = false
      })
    },
    deleteFormUserQuestionOption(form_question_option, colName) {
      this.loadingSaveAnswer = true
      let question_option_child = this.formUser.FormUserQuestions.find(
        (d) => d.FormQuestionId == this.question.Id
      ).FormUserQuestionOptionChilds.find(
        (d) =>
          d.FormQuestionOptionChildId ==
          form_question_option.Childs.filter((d) => d.Text == colName)[0].Id
      )
      let id = question_option_child.Id
      ApiService.delete('api/Form/FormUserQuestionOptionChild/' + id)
        .then(() => {
          ApiService.showSaveSuccess()
          this.$emit('getAnswers')
          this.loadingSaveAnswer = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loadingSaveAnswer = false
        })
    }
  }
}
</script>
