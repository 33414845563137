<template>
  <v-rating
      class="text-center"
      v-if="!loading"
      v-model="rating"
      length="5"
      clearable
      @input="saveFormUserQuestion()"
  >
    <template v-slot:item="props">
      <v-icon large :color="props.isFilled ? 'purple darken-4' : ''" @click="props.click">
        {{ `mdi-numeric-${props.index + 1}-box` }}
      </v-icon>
    </template>
  </v-rating>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'form-question-rating5-type',
  props: ['question', 'formUser'],
  data() {
    return {
      loadingSaveAnswer: false,
      rating: null,
      loading: true
    }
  },
  mounted() {
    if (this.formUser && this.formUser.FormUserQuestions) {
      for (let index = 0; index < this.formUser.FormUserQuestions.length; index++) {
        const element = this.formUser.FormUserQuestions[index]
        if (element.FormQuestionId == this.question.Id) {
          this.rating = parseInt(element.Answer)
          break
        }
      }
    }
    this.loading = false
  },
  methods: {
    saveFormUserQuestion() {
      this.loadingSaveAnswer = true
      let form_user_question = {
        FormUserId: this.formUser.Id,
        FormQuestionId: this.question.Id,
        Answer: this.rating.toString()
      }
      ApiService.setHeader()
      ApiService.put('api/Form/FormUserQuestion', form_user_question)
          .then(() => {
            ApiService.showSaveSuccess()
            this.loadingSaveAnswer = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loadingSaveAnswer = false
          })
    }
  }
}
</script>
