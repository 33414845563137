<template>
  <v-textarea
    v-model="answer"
    outlined
    placeholder="Buraya cevap yazılır"
    label="Cevap"
    persistent-hint
    class="mx-2 my-2"
    :hint="hint"
    :loading="loadingSaveAnswer"
    @keyup="saveFormUserQuestionOption"
    @keydown.enter="saveFormUserQuestionOption"
  >
  </v-textarea>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'form-question-open-ended-type',
  props: ['question', 'formUser'],
  data() {
    return {
      answer: '',
      loadingSaveAnswer: false,
      hint: '',
      timer: {},
      allowInput: false
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    saveFormUserQuestionOption() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.loadingSaveAnswer = true
        this.hint = 'Kaydediliyor...'
        let form_user_question = {
          FormUserId: this.formUser.Id,
          FormQuestionId: this.question.Id,
          Answer: this.answer
        }
        ApiService.setHeader()
        ApiService.put('api/Form/FormUserQuestion', form_user_question)
          .then(() => {
            this.loadingSaveAnswer = false
            this.hint = 'Kaydedildi'
            setTimeout(() => {
              this.hint = ''
            }, 1000)
          })
          .catch(({ response }) => {
            this.loadingSaveAnswer = false
            ApiService.showError(response)
          })
      }, 800)
    },
    getDataFromApi() {
      ApiService.setHeader()
      ApiService.post('api/Form/FormUserQuestion/GetTheAnswer', {
        FormUserId: this.formUser.Id,
        FormQuestionId: this.question.Id
      })
        .then((data) => {
          this.answer = data.data
          this.allowInput = true
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.allowInput = true
        })
    }
  }
}
</script>
