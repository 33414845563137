<template>
  <v-card v-if="!loading" elevation="0" height="'100%'">
    <v-card-title class="headline text-center">
      <v-row>
        <v-col cols="12" class="d-flex justify-content-between">
          <v-icon dense color="primary" @click="$emit('close')"> mdi-arrow-left</v-icon>
          <v-chip color="success" outlined label>{{ form.Name }}</v-chip>
          <span></span>
        </v-col>
        <v-col v-if="form.Description != null && form.Description.length > 0" class="d-flex justify-content-center">
          <v-sheet outlined rounded class="border-primary" color="primary" :max-width="500">
            <v-card-subtitle class="text-break text-white" style="font-size: 12px;">
              {{ form.Description }}
            </v-card-subtitle>
          </v-sheet>
        </v-col>
        <v-col v-if="form.ForCustomer && form.HasDifferentAssessmentUser" class="d-flex justify-content-center">
          <v-select v-model="selectedFormUserId" label="Form Kullanıcısı" dense outlined :items="formUsers"
                    hint="Formu kimin adına doldurduğunuzu seçiniz" persistent-hint @change="formUserChanged"
                    item-text="UserFullName" item-value="Id" style="max-width: 500px;"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text :class="$vuetify.breakpoint.mobile ? '' :'d-flex justify-content-center '">
      <v-list dense flat max-width="500" :key="compKey">
        <v-list-group
            :value="true"
            disabled
            v-for="(question, i) in form.FormQuestions"
            :key="i"
            no-action
            append-icon=""
            class="mx-1 my-2 border-primary border border-sm rounded"
        >
          <template v-slot:activator>
            <span class="mt-2 text-dark" style="font-size: 13px">  {{ i + 1 }}. {{ question.Text }}</span>
          </template>
          <FormQuestionCheckboxType
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Checkbox'"
              :formUser="formUser"
              :question="question"
          />
          <FormQuestionMatrixType
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Matris'"
              :formUser="formUser"
              :question="question"
          />
          <FormQuestionRating5Type
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Rating5'"
              :formUser="formUser"
              :question="question"
          />
          <FormQuestionRatingType
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Rating'"
              :formUser="formUser"
              :question="question"
          />
          <FormQuestionRadioButtonType
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Radio Button'"
              :formUser="formUser"
              :question="question"
          />
          <FormQuestionTrueFalseType
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Doğru Yanlış'"
              :formUser="formUser"
              :question="question"
          />
          <FormQuestionOpenEndedType
              @getAnswers="getAnswersFromApi()"
              v-if="question.FormQuestionTypeName == 'Açık Uçlu'"
              :formUser="formUser"
              :formId="formId"
              :question="question"
          />
        </v-list-group>
      </v-list>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-center">
      <v-btn color="primary" @click="$emit('completed')" :block="$vuetify.breakpoint.mobile"
             :large="!$vuetify.breakpoint.mobile">
        Formu Tamamla
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ApiService from '@/core/services/api.service.js'
import FormQuestionCheckboxType from '@/view/pages/form/FormApp/FormQuestionCheckboxType.vue'
import FormQuestionMatrixType from '@/view/pages/form/FormApp/FormQuestionMatrixType.vue'
import FormQuestionRating5Type from '@/view/pages/form/FormApp/FormQuestionRating5Type.vue'
import FormQuestionRatingType from '@/view/pages/form/FormApp/FormQuestionRatingType.vue'
import FormQuestionRadioButtonType from '@/view/pages/form/FormApp/FormQuestionRadioButtonType.vue'
import FormQuestionTrueFalseType from '@/view/pages/form/FormApp/FormQuestionTrueFalseType.vue'
import FormQuestionOpenEndedType from '@/view/pages/form/FormApp/FormQuestionOpenEndedType.vue'

export default {
  name: 'form-question-page',
  props: ['formId'],
  components: {
    FormQuestionCheckboxType,
    FormQuestionMatrixType,
    FormQuestionRating5Type,
    FormQuestionRatingType,
    FormQuestionRadioButtonType,
    FormQuestionTrueFalseType,
    FormQuestionOpenEndedType
  },
  data() {
    return {
      form: {},
      formUser: {},
      loading: true,
      selectedFormUserId: null,
      formUsers: [],
      compKey: 0
    }
  },
  mounted() {
    this.getFormUser()
    this.getQuestionsFromApi()
  },
  methods: {
    getFormUser() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/Form/FormUser', {
        sortBy: ['Id'],
        descending: [false],
        rowsPerPage: -1,
        page: 1,
        query: {
          FormId: this.formId,
        }
      }).then((data) => {
        if (data.data.Results.length === 0) {
          this.insertFormUser()
        } else {
          this.formUser = data.data.Results[0]

          if (this.formUser != null && this.formUser.Form != null) {
            this.formUsers = this.formUser.Form.ForCustomer && this.formUser.Form.HasDifferentAssessmentUser ?
                data.data.Results : []
          }
          this.selectedFormUserId = this.formUser.Id
          this.getAnswersFromApi()
        }
      }).catch(({response}) => {
        ApiService.showError(response)
        this.loading = false
      })
    },

    insertFormUser() {
      ApiService.setHeader()
      ApiService.put('api/Form/FormUser', {IsStudent: true, FormId: this.formId})
          .then(() => {
            this.getFormUser()
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    },

    getAnswersFromApi() {
      ApiService.setHeader()
      ApiService.get('api/Form/FormUser/' + this.formUser.Id)
          .then((data) => {
            this.formUser = data.data
            this.loading = false
            this.compKey += 1
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    getQuestionsFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.get('api/Form/' + this.formId)
          .then((data) => {
            this.form = data.data
            this.loading = false
            this.form.FormQuestions.map((s) => {
              s.FormQuestionOptions.map((d) => (d.loading = false))
            })
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },
    
    formUserChanged() {
      this.formUser = this.formUsers.find((s) => s.Id === this.selectedFormUserId)
      this.getAnswersFromApi()
    }
  }
}
</script>
