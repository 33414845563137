<template>
  <div v-if="!loading">
    <v-rating
        v-if="!isDropDown"
        v-model="rating"
        :length="question.RatingMaxValue"
        clearable
        @input="saveFormUserQuestion()"
    >
      <template v-slot:item="props">
        <v-icon large :color="props.isFilled ? 'purple darken-4' : ''" @click="props.click">
          {{ `mdi-numeric-${props.index + 1}-box` }}
        </v-icon>
      </template>
    </v-rating>
    <v-autocomplete
        class="col-12 col-sm-6 col-md-6 pl-3 pb-3 pr-3"
        v-if="isDropDown"
        v-model="rating"
        :items="arr"
        outlined
        hide-details
        clearable
        dense
        :placeholder="'En Fazla ' + question.RatingMaxValue"
        @input="saveFormUserQuestion()"
    >
      <template slot="item" slot-scope="{ item }">
        <span class="font-weight-bold">{{ item }}</span>
        <span class="font-weight-light">/{{ question.RatingMaxValue }}</span>&nbsp;Puan
      </template>
      <template slot="selection" slot-scope="{ item }">
        <span class="font-weight-bold">{{ item }}</span>
        <span class="font-weight-light">/{{ question.RatingMaxValue }}</span>&nbsp;Puan
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'form-question-rating-type',
  props: ['question', 'formUser'],
  data() {
    return {
      loadingSaveAnswer: false,
      rating: null,
      loading: true,
      isDropDown: false,
      arr: []
    }
  },
  mounted() {
    if (this.question && this.question.RatingMaxValue > 10) {
      this.isDropDown = true
      this.arr = [...Array(this.question.RatingMaxValue).keys()]
      this.arr.push(this.question.RatingMaxValue)
    }
    if (this.formUser && this.formUser.FormUserQuestions) {
      for (let index = 0; index < this.formUser.FormUserQuestions.length; index++) {
        const element = this.formUser.FormUserQuestions[index]
        if (element.FormQuestionId == this.question.Id) {
          this.rating = parseInt(element.Answer)
          break
        }
      }
    }
    this.loading = false
  },
  methods: {
    saveFormUserQuestion() {
      this.loadingSaveAnswer = true
      let form_user_question = {
        FormUserId: this.formUser.Id,
        FormQuestionId: this.question.Id,
        Answer: this.rating ? this.rating.toString() : null
      }
      ApiService.setHeader()
      ApiService.put('api/Form/FormUserQuestion', form_user_question)
          .then(() => {
            ApiService.showSaveSuccess()
            this.loadingSaveAnswer = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loadingSaveAnswer = false
          })
    }
  }
}
</script>
