var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.rows.length > 0
        ? _c(
            "v-list",
            { attrs: { subheader: "", "two-line": "", dense: "" } },
            [
              _vm._l(_vm.rows, function (item, idx) {
                return [
                  _c(
                    "v-list-item",
                    { key: idx },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-icon", { attrs: { color: "success" } }, [
                            _vm._v("mdi-check-all"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("v-toolbar-title", [
                                _vm._v(_vm._s(item.Name)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-action",
                        [
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: "Karne",
                                  expression: "'Karne'",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              attrs: {
                                icon: "",
                                to: {
                                  name: "birlesik-form-karnesi",
                                  params: { id: item.Id },
                                },
                              },
                            },
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-document-outline"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider", { key: item.Id, staticClass: "mx-2" }),
                ]
              }),
            ],
            2
          )
        : _c("v-alert", { staticClass: "mt-5", attrs: { type: "warning" } }, [
            _vm._v(" Yayınlanmış form karnesi bulunmamaktadır. "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }