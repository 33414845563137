<template>
  <div>
    <v-list-item
      v-for="(option, j) in question.FormQuestionOptions"
      :key="j"
      style="pointer-events: none"
    >
      <v-list-item-title>{{ j + 1 + '. ' + option.Text }}</v-list-item-title>
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-action>
        <v-btn
          v-if="
            formUser &&
            formUser.FormUserQuestions &&
            !formUser.FormUserQuestions.map((s) =>
              s.FormUserQuestionOptions.find((d) => {
                return d.QuestionOptionId == option.Id
              })
            ).filter((s) => s).length
          "
          icon
          :loading="loadingSaveAnswer"
        >
          <v-icon
            color="grey darken-1"
            style="pointer-events: auto"
            @click="saveFormUserQuestionOption(question, option)"
          >
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-btn>
        <v-btn
          v-if="
            formUser &&
            formUser.FormUserQuestions &&
            formUser.FormUserQuestions.map((s) =>
              s.FormUserQuestionOptions.find((d) => {
                return d.QuestionOptionId == option.Id
              })
            ).filter((s) => s).length
          "
          icon
          :loading="loadingSaveAnswer"
        >
          <v-icon
            color="grey darken-2"
            style="pointer-events: auto"
            @click="deleteFormUserQuestionOption(question, option)"
          >
            mdi-checkbox-marked-circle
          </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
export default {
  name: 'form-question-true-false-type',
  props: ['question', 'formUser'],
  data() {
    return {
      loadingSaveAnswer: false
    }
  },
  methods: {
    saveFormUserQuestionOption(form_question, form_question_option) {
      //to save a FormUserQuestionOption, first we should have a FormUserQuestion
      this.loadingSaveAnswer = true
      let form_user_question = {
        FormUserId: this.formUser.Id,
        FormQuestionId: form_question.Id
      }
      ApiService.setHeader()
      ApiService.put('api/Form/FormUserQuestion', form_user_question)
        .then((data) => {
          let FormUserQuestionId = data.data
          let form_user_question_option = {
            FormUserQuestionId: FormUserQuestionId,
            QuestionOptionId: form_question_option.Id
          }
          ApiService.put('api/Form/FormUserQuestionOption/radio-button', form_user_question_option)
            .then(() => {
              this.loadingSaveAnswer = false
              this.$emit('getAnswers')
            })
            .catch(({ response }) => {
              ApiService.showError(response)
              this.loadingSaveAnswer = false
            })
          ApiService.showSaveSuccess()
          this.loadingSaveAnswer = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loadingSaveAnswer = false
        })
    },
    deleteFormUserQuestionOption(form_question, form_question_option) {
      this.loadingSaveAnswer = true
      //lets find the id of formuserquestionoption entity and delete it
      let question = this.formUser.FormUserQuestions.find(
        (s) => s.FormQuestionId == form_question.Id
      )
      let id = question.FormUserQuestionOptions.filter(
        (s) => s.QuestionOptionId == form_question_option.Id
      )[0].Id
      ApiService.delete('api/Form/FormUserQuestionOption/' + id)
        .then(() => {
          ApiService.showSaveSuccess()
          this.$emit('getAnswers')
          this.loadingSaveAnswer = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
          this.loadingSaveAnswer = false
        })
    }
  }
}
</script>
